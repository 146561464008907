import React, {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import AuthContext, {AuthContextData} from "../providers/AuthContext";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";

const SignOutFeature = () => {
    const auth = useContext(AuthContext) as AuthContextData;
    const navigate = useNavigate();

    useEffect(() => {
        void auth.logout().then((res)=>{
            navigate('/login', {replace: true})
        });
    }, []);

    return <LoadingComponent/>;
}

export default SignOutFeature
