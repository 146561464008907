import { makeStyles } from "@mui/styles";

export const buttonStyle = makeStyles({

    btnTxt: {
        fontSize: 18,
    },

    btnTxtWrapperSize: {
        display: 'flex',
        alignItems: 'center',

        '& svg': {
            fontSize: 18
                },


        '& .iconSizexs':{
        '& svg': {
            fontSize: 16
            },
        },

         '& .iconSizesm':{
            '& svg': {
                fontSize: 20
                },
            },

        '& .iconSizemd':{
            '& svg': {
                fontSize: 20
                },
            },

        '& .iconSizelg':{
            '& svg': {
                fontSize: 22
                },
            },

        '& .iconSizexl':{
            '& svg': {
                fontSize: 24
                },
            },

        "& .xs":{
            fontSize: '12px',
        },

        "& .sm":{
            fontSize: '16px',
        },

        "& .md":{
            fontSize: '18px',
        },
        "& .lg":{
            fontSize: '20px',
        },
        "& .xl":{
            fontSize: '22px',
        }
    },

    iconWrapper:{
        display: 'flex',
        paddingRight: 5,
        alignItems: 'center',

        '& span': {
            display: 'flex',
            alignItems: 'center',
        },


    '& .iconColorprimary': {
        '& svg': {
            color: '#171d4b',
                },
    },

    '& .iconColorsecondary': {
        '& svg': {
            color: '#2ed2c4',
                },
    },

    '& .iconColorsuccess': {
        '& svg': {
            color: '#b4f397',
                },
    },

    '& .iconColorinfo': {
        '& svg': {
            color: '#1976d2',
                },
    },

    '& .iconColordanger': {
        '& svg': {
            color: '#dd6565',
            border: '0px solid black',
                },
    },
    },

    iconWrapperNoLabel:{
        '& .iconColorsecondary': {
            '& svg': {
                color: '#2ed2c4',
                    },
        },

        '& .iconColorsuccess': {
            '& svg': {
                color: '#b4f397',
                    },
        },

        '& .iconColorinfo': {
            '& svg': {
                color: '#1976d2',
                    },
        },

        '& .iconColordanger': {
            '& svg': {
                color: '#dd6565',
                border: '0px solid black',
                    },
        },
    },


    DefaultLinkBtnColor: {
        '& .loadingSpinnerWrapper': {
            paddingTop: 5,
            '& svg': {
                margin: 0
                    },
           },
        '& .round': {
            borderRadius: 100
        },

        '& .Mui-disabled': {
            background: 'rgba(0, 0, 0, 0.1) !important',

        },
        '& .transparent': {
            border: '0px solid black',
            background: 'transparent',
            boxShadow: 'none',

            '&:hover':{
                background: 'transparent'
            }
        },

        '& .primary': {
            color: '#181d4b',
            border: '1px solid #181d4b',
        },

        '& .success': {
            color: '#b4f397',
            border: '1px solid #b4f397',
        },

        '& .secondary': {
            color: '#2ed2c4',
            border: '1px solid #2ed2c4'
        },


        '& .info': {
            color: '#1976d2',
            border: '1px solid #1976d2'
        },

        '& .danger': {
            color: '#dd6565',
            border: '1px solid #dd6565',

        },

    },



    DefaultLinkBtnColorContained: {



        '& .loadingSpinnerWrapper': {
            paddingTop: 5,
            '& svg': {
                margin: 0,
                color: 'whitesmoke'
                    },
           },


        '& .round': {
            borderRadius: 100
        },

        '& .Mui-disabled': {
            background: 'rgba(0, 0, 0, 0.1) !important',
        },

        '& .transparent': {
            border: '0px solid black',
            background: 'transparent',
            boxShadow: 'none',

            '&:hover':{
                background: 'transparent'
            }
        },

        '& .primary': {
            color: 'whitesmoke',
            background: '#181d4b'


        },

        '& .secondary': {
            color: 'whitesmoke',
            background: '#2ed2c4'

        },

        '& .success': {
            color: 'whitesmoke',
            background: '#8cc94a'
        },


        '& .info': {
            color: 'whitesmoke',
            background: '#1976d2',
        },

        '& .danger': {
            color: 'whitesmoke',
            background: '#dd6565'

        },

    },

})
