import { makeStyles } from "@mui/styles";

export const notFoundStyle = makeStyles({

    root:{
        display: 'flex',
        height: "50vh",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center",

        "& svg": {
            fontSize: "176px",
            color: "#2ed2c4"
        },

        "& .MuiTypography-root":{
            fontSize: "3rem",
            marginTop: "20px"
        },

        "& button": {
            marginTop: "20px"
        }
    }
})