import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";

interface OwnProps {
    children: React.ReactElement
}

type Props = OwnProps;

const AuthGuard: FunctionComponent<Props> = ({children}) => {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const getToken = window.localStorage.getItem('accessToken')
        if (getToken == null) {
            navigate('/login')
        }
        setIsLoading(false)
    }, []);

    if (isLoading) {
        return <LoadingComponent/>;
    }

    return children;

};

export default AuthGuard;
