import React from 'react'
import { CircularProgress, } from "@mui/material";
import { Box } from "@mui/system";
import LoadinImage from '../../assests/logo-icon.svg'

const LoadingComponent = () => {
  return (
    <Box sx={{ width: '100%', height: '100vh', position: "absolute", display: 'flex', justifyContent: 'center', alignItems: 'center', top:0, left:0, bottom: 0, right: 0,background: "#171D4B"}}>
            <img src={LoadinImage} alt="" style={{position: 'absolute', left:"50%", top:"50%", width: '65px',height: '65px', transform: 'translate(-50%, -50%)'}}/>
            <CircularProgress sx={{color: '#2FD2C4'}} size={200} />
        </Box>
  )
}

export default LoadingComponent