import {styled} from "@mui/material/styles";

export const Container =  styled('div')(({ theme }) => ({
    marginTop: '84px',
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    height: "calc(100% - 94px)",
    overflow: "auto",
    position: "relative",
    paddingTop: '40px'
}));

export const LoginWrapper =  styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    maxWidth: '650px',
    width: '100%',
}));



export const ButtonContainer = styled('div')(({theme}) => ({
    width: '100%',
    "& button": {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        minHeight: '80px'
    }
}));
