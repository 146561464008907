import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import './locales/config'
import ThemeProvider from "./theme";
import {AuthProvider} from "./providers/AuthContext";
import RegisterProvider from "./providers/RegisterProvider";


ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider>
            <AuthProvider>
                <BrowserRouter>
                        <Routes/>
                </BrowserRouter>
            </AuthProvider>
        </ThemeProvider>
    </React.StrictMode>, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
