import React, {FunctionComponent, useEffect, useState} from 'react';
import {Product} from "../models/product";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {Paper, Typography} from "@mui/material";
import BtnAction from "../components/Buttons/BtnAction";
import {useTranslation} from "react-i18next";
import AppBarComponent from "../components/header/AppBarComponent";
import {RegisterContext, RegisterContextData} from "../providers/RegisterProvider";
import {ButtonContainer} from "../components/layout/Containers";

interface OwnProps {
}

type Props = OwnProps;

const StartScreen: FunctionComponent<Props> = (props) => {
    const {setProduct, setCurrentStep, products, reset} = React.useContext(RegisterContext) as RegisterContextData;

    const navigate = useNavigate();
    const {t} = useTranslation()


    const handleParking = (productItem: Product) => {
        setProduct(productItem)
        setCurrentStep(2)
        navigate("/register");
    }

    const startParkeringSelect = () => {

        if (products && products.length == 1)
            handleParking(products[0])

        if (products && products.length > 1) {
            setCurrentStep(1)
            navigate("/register")
        }

    }

    useEffect(() => {
        setCurrentStep(0)
        reset()
    }, []);


    return (
        <>

            <AppBarComponent/>

                <Start>
                    <Head>
                        <Typography variant="h2" component="div">{t('general.guestParking')}</Typography>
                        <Typography variant="subtitle1"
                                    component="div">{t('general.hereYouCanRegisterParking')}</Typography>
                    </Head>
                    <ButtonContainer>
                        <BtnAction
                            size="lg"
                            fulldwidth={true}
                            action={() => startParkeringSelect()}
                            label={t('general.startParking')}
                            btnType="contained"
                            color="primary"
                        />
                    </ButtonContainer>
                </Start>

        </>);
};

export default StartScreen;

const Start = styled(Paper)(({theme}) => ({
    width: '100%',
    marginTop: "20px",
    maxWidth: '600px',
    textAlign: 'center',
    margin: '20px 5px 0',
    background: "#b9fff9",
    borderRadius: "20px",
    overflow: "hidden",
}));

const Head = styled('div')(({theme}) => ({
    padding: "60px 60px 30px 60px",
    "& .MuiTypography-h2": {
        fontSize: '36px',
        fontWeight: '600',
        color: "#171d4b"
    },

    "& 	.MuiTypography-subtitle1": {
        marginTop: '10px',
        paddingBottom: "10px",
        color: "#171d4b"
    }

}));
