import React, {useContext} from 'react'
import {Paper, Alert, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik, ErrorMessage} from 'formik';
import BtnAction from '../components/Buttons/BtnAction';
import InputTextField from '../components/inputtextfield/InputTextField';
import AuthContext from "../providers/AuthContext";
import {styled} from "@mui/material/styles";
import logo from "../assests/logo.svg";

const MainLoginFeature = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const loginValidationSchema = Yup.object().shape({
        email: Yup.string().required('Email skal udfyldes'),
        password: Yup.string().required('adgangskode skal udfyldes')
    });


    return (
        <Container>
                <LoginWrapper>
                    <Header>
                        <Typography variant="h4" sx={{margin: '30px 0', color: '#fff'}}>Log ind</Typography>
                        <img src={logo} alt="logo" />
                    </Header>

                    <Formik initialValues={{email: '', password: '', error: null}}
                            validationSchema={loginValidationSchema}
                            onSubmit={(values, {setFieldError, setSubmitting}) => {
                                if (auth) {
                                    auth.login(values.email, values.password).then(() => {
                                        navigate('/')
                                    }).catch(() => {
                                        setSubmitting(false)
                                        setFieldError('error', "Ugyldig brugernavn/password")
                                    });
                                }
                            }
                            }>
                        {({handleSubmit, isSubmitting, errors}) => (
                            <form onSubmit={handleSubmit}>
                                <InputTextField name="email" type="text" placeholder='Brugernavn' autoComplete="email"/>
                                <InputTextField name="password" type="password" placeholder='password' autoComplete="password"/>
                                <ErrorMessage name="error" render={() => <Alert severity="error">{errors.error}</Alert>}/>
                                <BtnAction
                                    fulldwidth={true}
                                    isSubmitting={isSubmitting}
                                    action={handleSubmit}
                                    type="submit"
                                    label="Login"
                                    btnType="contained"
                                    color="secondary"
                                    size='lg'
                                />
                            </form>
                        )}

                    </Formik>
                </LoginWrapper>
        </Container>
    )
}
export const LoginWrapper =  styled(Paper)(() => ({
    maxWidth: '650px',
    padding: '60px 40px',
    width: '100%',
    background: 'transparent',
    boxShadow:'none',
    'button':{
        marginTop: '30px',
      lineHeight: '50px'
    },
    'input':{
        color: '#fff',

    },
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
        color: '#fff',
    }
}));

const Container =  styled('div')(() => ({
    height: "100vh",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    background: '#181d4b'
}));

export const Header =  styled('div')(() => ({
    display: 'flex',
    'img':{
        marginLeft: 'auto',
        maxWidth: '200px'
    }
}));

export default MainLoginFeature
