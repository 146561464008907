import React from "react";
import { useField } from "formik";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';

interface Probs {
    placeholder?: string;
    name: string,
    type: string,
    label? : string
    required? : boolean
    transparent?: boolean
    autoComplete?:string;
}

export default function InputTextField({autoComplete, ...probs}: Probs){
    const [field, meta] = useField(probs.name)
    return (
            <>
            <FormControl fullWidth>
            { probs.type === "textarea" ? (
            <TextField
                variant="outlined"
                margin="normal"
                multiline
                autoComplete={autoComplete}
                rows={7}
                id={probs.name}
                label={probs.label}
                style={{background: '#fff', marginTop:5}}
            {...field} {...probs}/>
            ): <TextField
            variant="outlined"
            margin="normal"
            autoComplete={autoComplete}
            id={probs.name}
            label={probs.label}
            style={{background: probs.transparent ? '#fff': 'transparent', marginTop:5}}
            {...field} {...probs}/> }
            </FormControl>

        {meta.touched && meta.error ? (
            <Alert severity="error">{meta.error}</Alert>
        ): null}
            </>


    )
}
