import {Divider, Paper, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import BtnAction from '../components/Buttons/BtnAction'
import {useNavigate} from 'react-router-dom'
import React, {useContext, useEffect} from 'react'
import {RegisterContext, RegisterContextData} from "../providers/RegisterProvider";
import {fDateTime} from "../utils/formatTime";
import {ButtonContainer} from "../components/layout/Containers";
import {styled} from "@mui/material/styles";
import AppBarComponent from "../components/header/AppBarComponent";
import {durationValuesText} from "../models/product";

const ReceiptFeature = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {permit, product, reset} = useContext(RegisterContext) as RegisterContextData;

    useEffect(() => {
        if (permit == undefined) {
            navigate("/")
        }


        const timer = setTimeout(() => {
            navigate("/");
        }, 20000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <AppBarComponent/>
            {permit && product &&
                <Wrapper>
                    <Typography variant="h4" component="h2" sx={{marginTop: '30px'}}>
                        {product.name}
                    </Typography>
                    <Typography variant="h5" component="h3">
                        {t(durationValuesText[product.durationType], {duration: product.durationValue})}
                    </Typography>
                    <Divider sx={{margin: '30px 0 20px'}}/>
                    <Typography variant="overline" component="h4">
                        {t('general.validFrom') + ":"}<span>{fDateTime(permit.startDate)}</span>
                    </Typography>
                    <Typography variant="overline" component="h4">
                        {t('general.validTo') + ":"}<span>{permit.endDate ? fDateTime(permit.endDate) : "Indtil slettet"}</span>
                    </Typography>
                    <Divider sx={{margin: '30px 0 20px'}}/>
                    <Typography variant="overline" component="h4">
                        {t('general.registrationNumber') + ":"} <span>{permit.licensePlates}</span>
                    </Typography>
                    <ButtonContainer sx={{marginTop: '40px'}}>
                        <BtnAction
                            disabled={!product}
                            size="lg"
                            fulldwidth={true}
                            action={() => {
                                reset();
                                navigate('/register')
                            }}
                            label={t('general.registerNewParking')}
                            btnType="contained"
                            color="primary"
                        />
                    </ButtonContainer>
                </Wrapper>
            }
        </>
    )
}


const Wrapper = styled(Paper)(({theme}) => ({
    width: '100%',
    marginTop: "20px",
    maxWidth: '600px',
    textAlign: 'center',
    margin: '20px 5px 0',
    background: "#b9fff9",
    borderRadius: "20px",
    overflow: "hidden",
    'h4': {
        display: 'flex',
        padding: '0 50px',
        fontSize: '15px',
        fontWeight: '700',
        'span': {
            marginLeft: 'auto',
            fontWeight: '400'
        }
    },
    'h1': {
        fontSize: '22px',
        textTransform: 'uppercase',
        fontWeight: '500',
        lineHeight: "70px",
        color: '#171d4b'
    },
}));

export default ReceiptFeature
