import React from 'react'
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton } from '@mui/material';
import {Menu, MenuList, MenuItem, Button} from '@mui/material';
import {langStyle} from './style'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';


const LanguageComponent = () => {
    const classes = langStyle()
    const { t } = useTranslation()

    const [open, setOpen] = React.useState(false);
    const anchorRef= React.useRef<HTMLButtonElement>(null);

    const handleDaLng = () => {
      i18next.changeLanguage('da')
    }
  
    const handleEnLng = () => {
      i18next.changeLanguage('en')
    }

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
  
      setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

  return (
    <div className={classes.root}>
      <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <LanguageIcon />
        </IconButton>
      <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleDaLng}>{t('general.langDa')}</MenuItem>
                    <MenuItem onClick={handleEnLng}>{t('general.langEn')}</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </div>
  )
}

export default LanguageComponent