import {createContext, useEffect, useState} from "react";
import {Product, Permit} from "../models/product";
import React, { FunctionComponent } from 'react';
import {useTranslation} from "react-i18next";
import {getProducts} from "../services";
import {useNavigate} from "react-router-dom";

export interface RegisterContextData {
    currentStep: number,
    product?: Product
    setProduct: (product: Product) => void
    permit?: Permit
    products: Product[] | undefined
    setPermit: (permit: Permit) => void
    setCurrentStep: (index: number) => void,
    error: string | undefined
    setError: (error: string | undefined) => void
    reset: () => void
    steps: any
}


interface OwnProps {
    children: React.ReactNode
}

type Props = OwnProps;



export const RegisterContext = createContext<RegisterContextData|null>(null);

const RegisterProvider: FunctionComponent<Props> = ({children}) => {
    const {t} = useTranslation()
    const navigate = useNavigate();

    const stepList  = [
        {
            title: t('general.welcome'),
        },
        {
            title: t('general.choose'),
        },
        {
            title: t('general.registerVehicle'),
        },
        {
            title: t('general.sendConfirmation'),
            info:  t('general.smsInfo'),
        },
        {
            title: t('general.receipt'),
        }
    ];

    const [permit, setPermit] = useState<Permit>();
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState(stepList);
    const [product, setProduct] = useState<Product>();
    const [products, setProducts] = useState<Product[]>();
    const [error, setError] = useState<string>();

    const selectProduct = (product: Product) =>{
        setProduct(product)
    }

    const finish = ( permit : Permit) =>{
        setPermit(permit)
    }

    const reset = ()=>{
        setProduct(undefined)
        setPermit(undefined)
    }

    useEffect(() => {
        if(localStorage.getItem("accessToken")){
            setSteps(steps);
            getProducts()
                .then((response) => {
                    setProducts(response)
                })
                .catch((err: any) => {

                })
        }else{
            navigate('/login')
        }

    }, [t]);


    return (
        <RegisterContext.Provider value={{
            steps,
            error, setError,
            currentStep,
            products,
            setCurrentStep,
            permit,
            setPermit: finish,
            product,
            setProduct: selectProduct,
            reset,}}>
            {children}
        </RegisterContext.Provider>
    );
}


export default RegisterProvider;
