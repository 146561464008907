import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { refreshToken } from '../services';
import i18next from "i18next";

let apiClient = axios.create(
    {
        baseURL: process.env.REACT_APP_API_URL,
    }
)


apiClient.interceptors.request.use(async config => {
    const t = window.localStorage.getItem('accessToken')
    if (t) {
        config.headers = {
            'Authorization': `Bearer ${t}`,
            'Accept-Language': i18next.language,
            Accept: 'application/json;'
        }
    }
    return config;
});

apiClient.interceptors.response.use(async response => {
    return response;
}, ((error: AxiosError) => {
    const { data, status } = error.response!;
    const axiosConfig = error.config;
    switch (status) {
        case 400:
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            } else {
                toast.error(data.message)
                throw data;
            }
        case 409:
            toast.error(data.message)
            break;
        case 401:
            const token = localStorage.getItem('refreshToken');
            if (token) {
                return refreshToken(token).then((response) => {
                    const accessToken = response.data.access_token;

                    const refreshToken = response.data.refresh_token;
                    localStorage.setItem('accessToken', accessToken)
                    localStorage.setItem('refreshToken', refreshToken)
                    apiClient.defaults.headers.common["Authorization"] =
                        "Bearer " + accessToken;
                    if (axiosConfig && axiosConfig.headers) {
                        axiosConfig.headers["Authorization"] = "Bearer " + accessToken;
                    }

                    return axios(axiosConfig)

                }).catch((error) => {
                    console.log('Refresh Token Failed', error)
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    window.location.replace('/login')
                });
            }else{
                window.location.replace('/login')
            }
            toast.error('401')
            break;
        case 404:
            toast.error('404');
            break;
        case 403:
            break;
        case 405:
            toast.error("Server fejl");
            break;
        case 500:
            toast.error(data.message);
            throw data;
    }
    return Promise.reject(error);
}))


const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const request = {
    get: <T>(url: string) => apiClient.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => apiClient.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => apiClient.put<T>(url, body).then(responseBody),
    delete: <T>(url: string) => apiClient.delete<T>(url).then(responseBody),
}


const api = {
    request
}


export default api;
