import React, {useContext, useEffect, useState} from "react";
import {Paper, TextField, Typography} from "@mui/material";
import {RegisterContext, RegisterContextData} from "../providers/RegisterProvider";
import {useNavigate} from "react-router-dom";
import BtnAction from "../components/Buttons/BtnAction";
import {useTranslation} from "react-i18next";
import AppBarComponent from "../components/header/AppBarComponent";
import {styled} from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import {ButtonContainer} from "../components/layout/Containers";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";
import {registerParkingEndpointAsync} from "../services";

const RegisterParking = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [lp, setLp] = useState<string>("");
    const [pn, setPn] = useState<string>("");
    const {
        product,
        setProduct,
        products,
        steps,
        currentStep,
        setCurrentStep,
        setPermit,
        reset,
        setError,
        error,
    } = useContext(RegisterContext) as RegisterContextData;

    const fullReset = () => {
        reset();
        navigate('/');
    }

    const handleNext = () => {
        setIsSubmitting(true);
        setError(undefined)

        if (currentStep == 2) {
            if (lp.length < 4) {
                setError("Tilføj et gyldigt køretøj")
                setIsSubmitting(false)
                return;
            }

            if (product && product.utilizationConfirmationSms) {
                setCurrentStep(3)
                setIsSubmitting(false)
            } else {
                handleParking();
            }
        }

        if (currentStep == 3) {
            handleParking();
        }

    }
    const handleParking = () => {
        console.log(product, lp);
        if (product && lp.length > 2) {
            registerParkingEndpointAsync({
                propertyId: product.propertyId,
                tenantId: product.tenantId,
                productId: product.id!,
                phoneNumber: pn,
                licensePlates: [lp],
            }).then((res) => {
                setPermit(res);
                setCurrentStep(steps.length - 1)
                navigate("/finish")
            }).catch((error) => {
                if (error.message)
                    setError(error.message);

                if (error.errorCode && error.errorCode == "vehicle_quarantined") {
                    setCurrentStep(2);
                }
                if (error.errorCode && error.errorCode == "utilization_limit_exceeded") {
                    setCurrentStep(1);
                }


            }).finally(() => {
                setIsSubmitting(false);
            })
        } else {
            setError("DER OPSTOD EN UVENTET FEJL!")
            setIsSubmitting(false);
        }
    }


    useEffect(() => {

        if (products && products.length == 1) {
            setProduct(products[0])
            setCurrentStep(2);
        } else if (products && products.length > 1) {
            setCurrentStep(1)
        }

    }, [products]);

    if (!products)
        return <LoadingComponent/>

    return (
        <>
            <AppBarComponent/>
            {error && <Alert severity="error" elevation={1}
                             sx={{margin: '20px 0', width: '100%', maxWidth: '600px'}}>{error}</Alert>}

            {currentStep == 1 &&
                <>
                    {products && products.map((productItem, index) => (
                        <ProductItem
                            key={productItem.id}
                            sx={{
                                ...(product && productItem.id == product.id ? {background: "#22c5b8"} : {background: "#b9fff9"}),
                                ...(products.length != index + 1 && {borderBottom: "2px solid #171d4b"})
                            }}
                            onClick={() => setProduct(productItem)}
                        >
                            <Typography variant="h2" component="h1">{productItem.name}</Typography>
                        </ProductItem>
                    ))}
                    <ButtonContainer>
                        <BtnAction
                            disabled={!product}
                            size="lg"
                            fulldwidth={true}
                            action={() => product && setCurrentStep(2)}
                            label={t('general.next')}
                            btnType="contained"
                            color="primary"
                        />
                    </ButtonContainer>

                </>
            }

            {currentStep == 2 &&
                <Wrapper>
                    <TextField
                        autoComplete="off"
                        sx={{...(error && {'.MuiOutlinedInput-notchedOutline': {borderColor: "red !important"}})}}
                        placeholder={t('general.licensePlate')}
                        value={lp}
                        autoFocus={true}
                        inputProps={{autoComplete: "off", autoFocus: true}}
                        onChange={(e) => {
                            setLp(e.target.value)
                        }}
                        fullWidth
                    />
                    <BtnAction
                        fulldwidth={true}
                        action={handleNext}
                        label={t('general.next')}
                        btnType="contained"
                        isSubmitting={isSubmitting}
                        color="primary"
                    />
                </Wrapper>}

            {currentStep == 3 &&
                <>
                    {<Alert severity="info" elevation={1} sx={{
                        margin: '20px 0',
                        width: '100%',
                        maxWidth: '600px',
                        padding: '30px 20px'
                    }}>{steps[currentStep].info!}</Alert>}
                    <Wrapper>
                        <TextField
                            sx={{...(error && {'.MuiOutlinedInput-notchedOutline': {borderColor: "red !important"}})}}
                            placeholder={t('general.phoneNumber')}
                            onChange={(e) => setPn(e.target.value)}
                            fullWidth
                            autoComplete="off"
                            inputProps={{autoComplete: "off"}}
                        />
                        <BtnAction
                            fulldwidth={true}
                            isSubmitting={isSubmitting}
                            action={handleNext}
                            label={t('general.next')}
                            btnType="contained"
                            color="primary"
                        />
                    </Wrapper>
                </>}


        </>
    );
};

const ProductItem = styled(Paper)(({theme}) => ({
    width: '100%',
    borderRadius: 0,
    textAlign: 'center',
    overflow: "hidden",
    "&:hover": {
        cursor: 'pointer'
    },
    'h1': {
        fontSize: '22px',
        textTransform: 'uppercase',
        fontWeight: '500',
        lineHeight: "70px",
        color: '#171d4b'
    },
}));
const Wrapper = styled('div')(({theme}) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '600px',
    height: '80px',
    'input': {
        borderRadius: 0,
        height: '100%',
        padding: "0 20px",
        fontSize: '30px'
    },
    '.MuiInputBase-formControl': {
        height: '100%',
        padding: 0,
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#171d4b',
        borderRadius: 0,
        borderWidth: '3px',
        top: '-4px',
        '&.Mui-focused': {
            borderColor: "red",
            color: "red"
        }
    },
    'button': {
        height: '100%',
        borderWidth: '3px',
        borderRadius: 0,
        minWidth: '180px',
    }
}));
export default RegisterParking;
