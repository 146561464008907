import axios from 'axios';
import * as qs from 'qs'
import {Permit, Product} from "./models/product";
import api from "./api/apiClient";
import apiClient from "./api/apiClient";

export const  getProducts = async () : Promise<Product[]> =>{
    return await api.request.get<Product[]>(`v1/account/utilizable`)
}


const config = {
    headers: {"Content-Type": "application/x-www-form-urlencoded"}
}

export const signin = async (username: string, password: string) => {
    var data = qs.stringify({
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: "password",
        scope: "Api offline_access",
        username: username,
        password: password,
    });
    return await axios.post(process.env.REACT_APP_AUTH_URL + 'connect/token', data, config)

}

export const refreshToken = async (refreshToken: string) => {
    // let refreshToken = store.getState().auth.refreshToken;
    var data = qs.stringify({
        client_id: process.env.REACT_APP_CLIENT_ID,
        scope: "Api offline_access",
        grant_type: "refresh_token",
        refresh_token: refreshToken,
    });
    console.log(data);

    return axios.post(process.env.REACT_APP_AUTH_URL + 'connect/token', data, config);
};


export const registerParkingEndpointAsync = async ({propertyId, tenantId, productId, phoneNumber,licensePlates, ...other}: {licensePlates: string[], phoneNumber?:string, productId: string, propertyId: string, tenantId:string}) =>{
    return apiClient.request.post<Permit>(`v1/properties/${propertyId}/tenants/${tenantId}/products/${productId}`,
        {licensePlates,
            ...( phoneNumber != undefined && phoneNumber?.length > 4 ? {
                SendSmsConfirmation: true, phoneNumber} : {})
        }
    );
}

