import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'


i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'en',
  detection: {
    order: ['localStorage', 'htmlTag',  'cookie',  'path', 'subdomain'],
    caches: ['localStorage']
  },
  resources: {
    en: {
      translations: {
        "general": require('./en/general.json'),
        "dialog": require('./en/dialog.json'),
      }
    },
    da: {
      translations: {
        "general": require('./da/general.json'),
        "dialog": require('./da/dialog.json'),
      }
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'da'];

export default i18n;