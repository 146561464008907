import { makeStyles } from "@mui/styles";

export const appbarStyle = makeStyles({
    toolbarWrapperStyle:{
        margin: '0 auto',
        width: '100%',
        "& .seoTitle":{
            fontSize: "28px",
            textTransform: "uppercase",
            fontWeight: '600',
        },

        "& .showDisable": {
            border: "1px solid rgba(255, 255, 255, 0.4)",
            borderRadius: "5px",
           "& span": {
               color: "rgba(255, 255, 255, 0.3)"
           }
        }
    },

    toolbarContentWrapperStyle: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
       /*  background: 'red' */
        '& .logoWrapperStyle':{
          width: '150px',
        },
       "& .logoWrapperStyle:hover":{
           cursor: "pointer"
       }
    }

})
