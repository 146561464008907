import React from "react";
import { notFoundStyle } from "./style";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BtnAction from "../../components/Buttons/BtnAction";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const classes = notFoundStyle();
  const {t} = useTranslation()
  const navigate = useNavigate()

  const back = () => {
    navigate('/')
  }

  return (
    <div className={classes.root}>
      <div>
        <FindInPageIcon />
      </div>
      <div>
        <Typography variant="h1" component="div">
          {t('general.pageNotFound')}
        </Typography>
      </div>
      <div>
        <BtnAction
          action={back}
          btnType="contained"
          label={t('general.back')}
          color="secondary"
        />
      </div>
    </div>
  );
};

export default Index;
