import { makeStyles } from "@mui/styles";

export const langStyle = makeStyles({
    root:{
        position: "fixed",
        right: 30,
        bottom: 30,
        margin: "0 auto",
        maxWidth: "888px",
        marginTop: "70px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    }
})