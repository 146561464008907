import React, {useContext, useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { appbarStyle } from "./style";
import { useNavigate, useLocation } from "react-router-dom";
import BtnAction from "../Buttons/BtnAction";
import { useTranslation } from "react-i18next";
import logo from "../../assests/logo.svg";
import { Typography } from "@mui/material";
import {styled} from "@mui/material/styles";
import {RegisterContext, RegisterContextData} from "../../providers/RegisterProvider";
import {Simulate} from "react-dom/test-utils";
import reset = Simulate.reset;

interface OwnProps {
}

type Props = OwnProps;


export default function AppBarComponent({}: Props) {
  const classes = appbarStyle();
  const { t } = useTranslation();
  const [backActive, setBackActive] = useState(false);
  const {currentStep,setError, products, product, steps,setCurrentStep, setProduct, reset} = useContext(RegisterContext) as RegisterContextData;
  const navigate = useNavigate();
  const { pathname } = useLocation();


  const handleBack = () => {
    setError(undefined)
    if(currentStep == 1){
      navigate("/")
    }

    if(currentStep == 2 && product){
      if(products && products.length>1){
        reset();
        setCurrentStep(1)
      }else{
        navigate("/")
      }

    }

    if(currentStep == 3){
      setCurrentStep(2);
    }

    if(currentStep == 4){
      reset();
      navigate('/register')
    }
  };

  const  TopBar =  styled(AppBar)(({ theme }) => ({
    background: "#181d4b",
    padding: '10px'
  }));

  useEffect(() => {
    if(currentStep != 0){
      setBackActive(true);
    }else{
      setBackActive(false);
    }

  }, [currentStep]);


  return (
    <TopBar>
        <div className={classes.toolbarWrapperStyle}>
          <Toolbar>
            <div className={classes.toolbarContentWrapperStyle}>
              <div onClick={() => navigate("/")} className="logoWrapperStyle">
                <img src={logo} alt="logo" />
              </div>
              <Typography variant="h1" component="h1" className="seoTitle">
                {steps[currentStep].title}
              </Typography>
              <div className={!backActive ? "showDisable" : ""}>
              <BtnAction
                disabled={!backActive}
                action={handleBack}
                label={currentStep == 4 ? t("general.registerNew") : t("general.back")}
                btnType="contained"
                color="secondary"
              />
              </div>
            </div>
          </Toolbar>
        </div>
      </TopBar>
  );
}
