import { format, getTime, formatDistanceToNow } from 'date-fns';
import { da, enUS }  from "date-fns/locale";

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;
const options = {locale: da}
export function fDate(date: InputValue | string | number, newFormat?: string) {
    const fm = newFormat || 'dd MMM yyyy';
    return date ?  format(new Date(date), fm,options ) : '';
}
export function fDateDashed(date: InputValue | string | number) {

    return date ? format(new Date(date), "MM-dd-yy",options) : ''

}
export function fDateTime(date: InputValue | string | number, newFormat?: string) {
    const fm = newFormat || 'dd MMM yyyy HH:mm'
    return date ? format(new Date(date), fm,options) : '';
}

export function fTimestamp(date: InputValue | string | number) {
    return date ?  getTime(new Date(date)) : '';
}

export function fDateTimeSuffix(date: InputValue | string | number) {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm', options) : '';
}

export function fToNow(date: InputValue | string | number) {
    return date ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        ...options
    }) : '';
}
