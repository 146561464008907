import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import LangComponent from "../lang/LanguageComponent";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import {Container} from "./Containers";
import RegisterProvider from "../../providers/RegisterProvider";

function Layout() {

    return (
        <RegisterProvider>
        <Container>
            <Outlet/>
            <LangComponent/>
        </Container>
        </RegisterProvider>
    );
}

export default Layout;
