import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
    createTheme,
    ThemeOptions,
    ThemeProvider as MUIThemeProvider,
    StyledEngineProvider,
} from '@mui/material/styles';
// hooks
//

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {

    const isLight = true;

    const themeOptions: ThemeOptions = useMemo(
        () => ({

        }),
        []
    );

    const theme = createTheme(themeOptions);


    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
