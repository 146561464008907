export enum durationType {
    None,
    Hours,
    Days,
    Months
}

export const durationValuesText = [
    'general.duration.ChooseSelf',
    'general.duration.Hours',
    'general.duration.Days',
    'general.duration.Months',
    'general.duration.CalendarMonth',
];
export const durationTypeValues = [
    'durationType.Unlimited',
    'durationType.Hours',
    'durationType.Days',
    'durationType.Months',
    'durationType.CalendarMonth'
];
export interface Product {
    id?: string
    propertyId: string;
    tenantId: string;
    name: string
    durationType: number,
    durationValue:number,
    utilizationConfirmationSms?: boolean
    utilizationConfirmationEmail?: boolean
}



export interface Permit { id: string, name: string; licensePlates:string, startDate:Date, endDate?: Date }
