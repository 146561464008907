import React from 'react'
import {Navigate, useRoutes} from 'react-router-dom';
import Layout from '../components/layout';
import NotFound from "../features/NotFound"
import Forbidden from "../features/Forbidden"
import MainLoginFeature from '../features/MainLoginFeature';
import ReceiptFeature from '../features/ReceiptFeature';
import StartScreen from "../features/StartScreen";
import RegisterParking from "../features/RegisterParking";
import AuthGuard from "../AuthGuard";
import SignOutFeature from "../features/SignOutFeature";

const Routes: React.FC = (): JSX.Element => {
    const appRoutes = {
        path: '/',
        element: <Layout/>,
        children: [
            {path: '', element: <AuthGuard><StartScreen/></AuthGuard>},
            {path: 'register', element: <AuthGuard><RegisterParking/></AuthGuard>},
            {path: 'finish', element: <AuthGuard><ReceiptFeature/></AuthGuard>},
            {path: '*', element: <Navigate to='/404'/>}

        ]
    }

    const routing = useRoutes([appRoutes,
        {path: '/login', element: <MainLoginFeature/>},
        {path: '/sign-out', element: <SignOutFeature/>}, {
        path: '404',
        element: <NotFound/>,
    }, {path: 'forbidden', element: <Forbidden/>}]);

    return <>{routing}</>;

}

export default Routes;
