import React, {createContext, FunctionComponent, useState} from 'react';
import {signin} from "../services";

interface OwnProps {
 children: React.ReactNode
}

type Props = OwnProps;

export interface AuthContextData {
    initialized: boolean;
    signedIn: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextData|null>(null);
export const AuthProvider: FunctionComponent<Props> = ({children}) => {
    const [signedIn, setSignedIn] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const trySignIn = async (username: string, password: string) => {
            try {
                const response = await signin(username, password);
                localStorage.setItem("accessToken", response.data.access_token)
                localStorage.setItem("refreshToken", response.data.refresh_token);

            } catch (error) {
                throw error;
            }
    };
    const logout = async () => {
        try {
            localStorage.removeItem("accessToken")
            localStorage.removeItem("refreshToken");
        } catch (error) {
            throw error;
        }
    };

    return (<AuthContext.Provider value={{
        login: trySignIn,
        logout,
        signedIn,
        initialized}}>
        {children}
    </AuthContext.Provider>);
};

export default AuthContext;
