import React from 'react'
import { Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import { buttonStyle } from './style'

interface Props {
    action?: () => void
    ref?: any
    disabled?: boolean
    spinnerSize?: number
    label?: string
    type?: any
    Icon?: any
    IconColor?: "info" | "primary"| "secondary" |  "success" |  "danger"
    round?: boolean
    isSubmitting?: boolean,
    fulldwidth?: boolean,
    btnType: "text" | "outlined" | "contained" | undefined
    color: "info" | "primary"| "secondary" |  "success" | "transparent"|  "danger"
    size?: "xs" | "sm" | "md" | "lg" | "xl" | undefined

  };

const BtnAction: React.FC<Props>  = ({action, label, Icon, IconColor, btnType, round, spinnerSize, isSubmitting, fulldwidth, disabled, color, type, size}) => {
    const classes = buttonStyle()
  return (
    <div className={`${classes.btnTxtWrapperSize} ${btnType != 'contained' ?  `${classes.DefaultLinkBtnColor}`: `${classes.DefaultLinkBtnColorContained}`}`} >
      <Button
          sx={{fontWeight: '700'}}
      disabled={disabled}
      fullWidth={fulldwidth ?? false}
      onClick={action} type={type}  variant={btnType}  className={
        round == true ? `${color} round`:
        color}>
        {Icon != undefined ? <div className={label ==  undefined ? classes.iconWrapperNoLabel : `${classes.iconWrapper} iconSize${size}`}>
          <span className={`iconColor${IconColor}`}>
          {Icon}
          </span>
          </div> : null}
      {isSubmitting ?
      <div className='loadingSpinnerWrapper'>
      <CircularProgress size={spinnerSize == undefined ? 20 : spinnerSize} />
      </div>
      : <span className={`${size != undefined ? size : classes.btnTxt}`}>{label}</span> }
      </Button>
    </div>

    )
}

export default BtnAction
